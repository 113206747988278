//NPM Packages
import { React, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import styled from "styled-components";
import SignaturePad from "react-signature-canvas";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Col } from "../styles/Col";
import CheckBoxButton from "../styles/CheckBoxButton";
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";
import { Row } from "../styles/Row";

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const Hidden = styled.div`
  color: ${(props) => props.theme.colors.background};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const Label = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

const MessageCenter = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  text-align: center;
`;

const SignatureWrapper = styled.div`
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
  height: 300px;
`;

const SignatureButtonWrapper = styled.div`
  display: flex;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  align-items: center;
  justify-content: center;
`;

const Terms = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin-left: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const TermsHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin-left: ${(props) => props.theme.space[0]};
  font-size: medium;
  font-weight: 700;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationMessageCenter = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
  text-align: center;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function Declaration(props) {
  // jsPdf
  const doc = new jsPDF("portrait", "pt", "a4");
  const docHeight = doc.internal.pageSize.getHeight();
  const docWidth = doc.internal.pageSize.getWidth();
  let yPos = 0;
  const logoImg = new Image();
  logoImg.src = props.logoPath;
  const logoHeight = 74;
  const logoWidth = 129;

  const sigCanvas = useRef({});

  const {
    dev,
    data,
    setData,
    docsData,
    setDocsData,
    directors,
    headers,
    declarationDataCaptured,
    setDeclarationDataCaptured,
    docsError,
    setDocsError,
    docsSent,
    setDocsSent,
    lamininError,
    setLamininError,
    setLamininResponse,
    lamininSent,
    setLamininSent,
    lamininSubmitted,
    setLamininSubmitted,
    setDocsDataCaptured,
  } = useContext(MyContext);

  const [count, setCount] = useState(0);
  const [formError, setFormError] = useState("");
  const [acceptTermsError, setAcceptTermsError] = useState("");
  const [signatureError, setSignatureError] = useState("");

  //Documents State
  const [pdfDocSubmitted, setPdfDocSubmitted] = useState(false);
  const [companyRegistrationDocSubmitted, setCompanyRegistrationDocSubmitted] =
    useState(false);
  const [companyRegistrationDocSent, setCompanyRegistrationDocSent] =
    useState(false);
  const [idCopiesDoc1Submitted, setIdCopiesDoc1Submitted] = useState(false);
  const [idCopiesDoc1Sent, setIdCopiesDoc1Sent] = useState(false);
  const [idCopiesDoc2Submitted, setIdCopiesDoc2Submitted] = useState(false);
  const [idCopiesDoc2Sent, setIdCopiesDoc2Sent] = useState(false);
  const [idCopiesDoc3Submitted, setIdCopiesDoc3Submitted] = useState(false);
  const [idCopiesDoc3Sent, setIdCopiesDoc3Sent] = useState(false);
  const [
    taxClearanceCertificateDocSubmitted,
    setTaxClearanceCertificateDocSubmitted,
  ] = useState(false);
  const [taxClearanceCertificateDocSent, setTaxClearanceCertificateDocSent] =
    useState(false);
  const [bankStatementDoc1Submitted, setBankStatementDoc1Submitted] =
    useState(false);
  const [bankStatementDoc1Sent, setBankStatementDoc1Sent] = useState(false);
  const [bankStatementDoc2Submitted, setBankStatementDoc2Submitted] =
    useState(false);
  const [bankStatementDoc2Sent, setBankStatementDoc2Sent] = useState(false);
  const [bankStatementDoc3Submitted, setBankStatementDoc3Submitted] =
    useState(false);
  const [bankStatementDoc3Sent, setBankStatementDoc3Sent] = useState(false);
  const [bankStatementDoc4Submitted, setBankStatementDoc4Submitted] =
    useState(false);
  const [bankStatementDoc4Sent, setBankStatementDoc4Sent] = useState(false);
  const [bankStatementDoc5Submitted, setBankStatementDoc5Submitted] =
    useState(false);
  const [bankStatementDoc5Sent, setBankStatementDoc5Sent] = useState(false);
  const [bankStatementDoc6Submitted, setBankStatementDoc6Submitted] =
    useState(false);
  const [bankStatementDoc6Sent, setBankStatementDoc6Sent] = useState(false);
  const [proofOfBankAccountDocSubmitted, setProofOfBankAccountDocSubmitted] =
    useState(false);
  const [proofOfBankAccountDocSent, setProofOfBankAccountDocSent] =
    useState(false);
  const [utilityBillDocSubmitted, setUtilityBillDocSubmitted] = useState(false);
  const [utilityBillDocSent, setUtilityBillDocSent] = useState(false);
  const [managementAccountsDocSubmitted, setManagementAccountsDocSubmitted] =
    useState(false);
  const [managementAccountsDocSent, setManagementAccountsDocSent] =
    useState(false);
  const [
    auditedFinanialStatementsDocSubmitted,
    setAuditedFinanialStatementsDocSubmitted,
  ] = useState(false);
  const [
    auditedFinanialStatementsDocSent,
    setAuditedFinanialStatementsDocSent,
  ] = useState(false);

  const intro =
    "I/We, the undersigned, on behalf of the Client and being duly authorized hereto, hereby:";
  const heading1 = "1.	Certification of Details";
  const par11 =
    "Certify that all details provided in this application are true and correct.";
  const heading2 = "2.	Consent to Credit Checks and Information Sharing";
  const par21 =
    "Consent to ZA Online Store trading as iStore (Registration Number 2003/022401/07)(“iStore”), any bank(s), finance house(s), or other third party/ies (“the funder/s”) to whom iStore may apply for credit facilities in the Client’s name, making enquiries about my/our credit record with any credit reference agency and any other relevant party to confirm the details on this application.";
  const par22 =
    "iStore, its affiliates, subsidiaries, divisions, and/or its cessionary/ies may provide credit reference agencies and bureaus with regular updates regarding how the Client manages its account, including any failure to meet agreed terms and conditions.";
  const par23 =
    "Credit reference agencies and bureaus may, in turn, share this information with other credit grantors. iStore and its cessionary/ies may disclose this information to any person deemed necessary to carry out rights or duties under the contract or applicable law related to the requested products.";
  const heading3 =
    "3.	Consent to Identity, Fraud Prevention, and Information Disclosure";
  const par31 =
    "Give iStore permission to conduct identity and fraud prevention checks and to share the information provided in this application with the South African Fraud Prevention Service. Additionally, consent is granted for iStore to disclose any information furnished by the Client to the funder/s and cessionary/ies, as necessary, to comply with their information requests or assess the Client’s application for credit facilities.";

  const declaration1 =
    "1) I/we consent to the the Rentor's Group (the group includes our affiliates, associates, subsidiaries and divisions, including our holding company) or its cessionary making enquiries about *my/ our credit record with any credit reference agency and credit bureaus and any other party to confirm the details on this application.";
  const declaration2 =
    "2) The Rentor or its cessionary may also provide credit reference agencies and credit bureaus with regular updates regarding how I/we manage my/our account, including my/our failure to meet agreed terms and conditions.";
  const declaration3 =
    "3) I/we consent that credit reference agencies and credit bureaus may, in turn, make the records and details available to other credit grantors. The Rentor or its cessionary may also give this information to any person who in its opinion, needs it to carry out any of the Rentor or its cessionary's rights or duties in terms of the contract or any law pertaining to the products I/we have requested.";
  const declaration4 =
    "4) I/we hereby give the Rentor or its cessionary permission to carry out identity and fraud prevention checks on me/us and to share the information provided in this application with the South African Fraud Prevention Service. I certify that the above details are true and correct.";
  const declaration5 =
    "5) We would like to provide you, your directors, and/or shareholders (“*you”) with information on products and services offered by the the Rentor's Group, and by your signature hereto, you hereby consent to the the Rentor's group to market to you our products and services, the sharing of personal information within the group for marketing purposes, and the group then marketing its products and services to you.";
  const declaration6 =
    "6) I/we consent to the the Rentor's Group, processing the required personnel information, as provided above and acknowledge that I understand the purposes of which it is required and for which it will be used.";
  const declaration7 =
    "7) I/We, the undersigned, hereby declare that the information provided herein is both true and correct, in every aspect and represents a true reflection of my personal, and in the event of a juristic person, it's financial position.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Submit companyRegistrationDoc
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.companyRegistrationName !== "" &&
      docsData.companyRegistrationBase64 !== null &&
      companyRegistrationDocSubmitted === false
    ) {
      setCompanyRegistrationDocSubmitted(true);
      const doc = {
        leadId: docsData.leadId,
        companyRegistrationName: docsData.companyRegistrationName,
        companyRegistrationBase64: docsData.companyRegistrationBase64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setCompanyRegistrationDocSent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    companyRegistrationDocSubmitted,
    setDocsError,
  ]);

  //Submit idCopiesDoc1
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.idCopies1Name !== "" &&
      docsData.idCopies1Base64 !== null &&
      idCopiesDoc1Submitted === false &&
      companyRegistrationDocSent
    ) {
      setIdCopiesDoc1Submitted(true);
      const doc = {
        leadId: docsData.leadId,
        idCopies1Name: docsData.idCopies1Name,
        idCopies1Base64: docsData.idCopies1Base64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setIdCopiesDoc1Sent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    idCopiesDoc1Submitted,
    setDocsError,
    companyRegistrationDocSent,
  ]);

  //Submit idCopiesDoc2
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.idCopies2Name !== "" &&
      docsData.idCopies2Base64 !== null &&
      idCopiesDoc2Submitted === false &&
      idCopiesDoc1Sent
    ) {
      setIdCopiesDoc2Submitted(true);
      const doc = {
        leadId: docsData.leadId,
        idCopies2Name: docsData.idCopies2Name,
        idCopies2Base64: docsData.idCopies2Base64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setIdCopiesDoc2Sent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    idCopiesDoc2Submitted,
    setDocsError,
    idCopiesDoc1Sent,
  ]);

  //Submit idCopiesDoc3
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.idCopies3Name !== "" &&
      docsData.idCopies3Base64 !== null &&
      idCopiesDoc3Submitted === false &&
      idCopiesDoc2Sent
    ) {
      setIdCopiesDoc3Submitted(true);
      const doc = {
        leadId: docsData.leadId,
        idCopies3Name: docsData.idCopies3Name,
        idCopies3Base64: docsData.idCopies3Base64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setIdCopiesDoc3Sent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    idCopiesDoc3Submitted,
    setDocsError,
    idCopiesDoc2Sent,
  ]);

  //Submit taxClearanceCertificateDoc
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.taxClearanceCertificateName !== "" &&
      docsData.taxClearanceCertificateBase64 !== null &&
      taxClearanceCertificateDocSubmitted === false &&
      idCopiesDoc3Sent
    ) {
      setTaxClearanceCertificateDocSubmitted(true);
      const doc = {
        leadId: docsData.leadId,
        taxClearanceCertificateName: docsData.taxClearanceCertificateName,
        taxClearanceCertificateBase64: docsData.taxClearanceCertificateBase64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setTaxClearanceCertificateDocSent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    taxClearanceCertificateDocSubmitted,
    setDocsError,
    idCopiesDoc3Sent,
  ]);

  //Submit bankStatementDoc1
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.bankStatement1Name !== "" &&
      docsData.bankStatement1Base64 !== null &&
      bankStatementDoc1Submitted === false &&
      taxClearanceCertificateDocSent
    ) {
      setBankStatementDoc1Submitted(true);
      const doc = {
        leadId: docsData.leadId,
        bankStatement1Name: docsData.bankStatement1Name,
        bankStatement1Base64: docsData.bankStatement1Base64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setBankStatementDoc1Sent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    bankStatementDoc1Submitted,
    setDocsError,
    taxClearanceCertificateDocSent,
  ]);

  //Submit bankStatementDoc2
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.bankStatement2Name !== "" &&
      docsData.bankStatement2Base64 !== null &&
      bankStatementDoc2Submitted === false &&
      bankStatementDoc1Sent
    ) {
      setBankStatementDoc2Submitted(true);
      const doc = {
        leadId: docsData.leadId,
        bankStatement2Name: docsData.bankStatement2Name,
        bankStatement2Base64: docsData.bankStatement2Base64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setBankStatementDoc2Sent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    bankStatementDoc2Submitted,
    setDocsError,
    bankStatementDoc1Sent,
  ]);

  //Submit bankStatementDoc3
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.bankStatement3Name !== "" &&
      docsData.bankStatement3Base64 !== null &&
      bankStatementDoc3Submitted === false &&
      bankStatementDoc2Sent
    ) {
      setBankStatementDoc3Submitted(true);
      const doc = {
        leadId: docsData.leadId,
        bankStatement3Name: docsData.bankStatement3Name,
        bankStatement3Base64: docsData.bankStatement3Base64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setBankStatementDoc3Sent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    bankStatementDoc3Submitted,
    setDocsError,
    bankStatementDoc2Sent,
  ]);

  //Submit bankStatementDoc4
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.bankStatement4Name !== "" &&
      docsData.bankStatement4Base64 !== null &&
      bankStatementDoc4Submitted === false &&
      bankStatementDoc3Sent
    ) {
      setBankStatementDoc4Submitted(true);
      const doc = {
        leadId: docsData.leadId,
        bankStatement4Name: docsData.bankStatement4Name,
        bankStatement4Base64: docsData.bankStatement4Base64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setBankStatementDoc4Sent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    bankStatementDoc4Submitted,
    setDocsError,
    bankStatementDoc3Sent,
  ]);

  //Submit bankStatementDoc5
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.bankStatement5Name !== "" &&
      docsData.bankStatement5Base64 !== null &&
      bankStatementDoc5Submitted === false &&
      bankStatementDoc4Sent
    ) {
      setBankStatementDoc5Submitted(true);
      const doc = {
        leadId: docsData.leadId,
        bankStatement5Name: docsData.bankStatement5Name,
        bankStatement5Base64: docsData.bankStatement5Base64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setBankStatementDoc5Sent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    bankStatementDoc5Submitted,
    setDocsError,
    bankStatementDoc4Sent,
  ]);

  //Submit bankStatementDoc6
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.bankStatement6Name !== "" &&
      docsData.bankStatement6Base64 !== null &&
      bankStatementDoc6Submitted === false &&
      bankStatementDoc5Sent
    ) {
      setBankStatementDoc6Submitted(true);
      const doc = {
        leadId: docsData.leadId,
        bankStatement6Name: docsData.bankStatement6Name,
        bankStatement6Base64: docsData.bankStatement6Base64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setBankStatementDoc6Sent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    bankStatementDoc6Submitted,
    setDocsError,
    bankStatementDoc5Sent,
  ]);

  //Submit proofOfBankAccountDoc
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.proofOfBankAccountName !== "" &&
      docsData.proofOfBankAccountBase64 !== null &&
      proofOfBankAccountDocSubmitted === false &&
      bankStatementDoc6Sent
    ) {
      setProofOfBankAccountDocSubmitted(true);
      const doc = {
        leadId: docsData.leadId,
        proofOfBankAccountName: docsData.proofOfBankAccountName,
        proofOfBankAccountBase64: docsData.proofOfBankAccountBase64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setProofOfBankAccountDocSent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    proofOfBankAccountDocSubmitted,
    setDocsError,
    bankStatementDoc6Sent,
  ]);

  //Submit utilityBillDoc
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.utilityBillName !== "" &&
      docsData.utilityBillBase64 !== null &&
      utilityBillDocSubmitted === false &&
      proofOfBankAccountDocSent
    ) {
      setUtilityBillDocSubmitted(true);
      const doc = {
        leadId: docsData.leadId,
        utilityBillName: docsData.utilityBillName,
        utilityBillBase64: docsData.utilityBillBase64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setUtilityBillDocSent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    utilityBillDocSubmitted,
    setDocsError,
    proofOfBankAccountDocSent,
  ]);

  //Submit managementAccountsDoc
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.managementAccountsName !== "" &&
      docsData.managementAccountsBase64 !== null &&
      managementAccountsDocSubmitted === false &&
      utilityBillDocSent
    ) {
      setManagementAccountsDocSubmitted(true);
      const doc = {
        leadId: docsData.leadId,
        managementAccountsName: docsData.managementAccountsName,
        managementAccountsBase64: docsData.managementAccountsBase64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setManagementAccountsDocSent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    managementAccountsDocSubmitted,
    setDocsError,
    utilityBillDocSent,
  ]);

  //Submit auditedFinanialStatementsDoc
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.auditedFinanialStatementsName !== "" &&
      docsData.auditedFinanialStatementsBase64 !== null &&
      auditedFinanialStatementsDocSubmitted === false &&
      managementAccountsDocSent
    ) {
      setAuditedFinanialStatementsDocSubmitted(true);
      const doc = {
        leadId: docsData.leadId,
        auditedFinanialStatementsName: docsData.auditedFinanialStatementsName,
        auditedFinanialStatementsBase64:
          docsData.auditedFinanialStatementsBase64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setAuditedFinanialStatementsDocSent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    auditedFinanialStatementsDocSubmitted,
    setDocsError,
    managementAccountsDocSent,
  ]);

  //Submit pdfDoc
  useEffect(() => {
    if (
      docsData.leadId !== "" &&
      docsData.pdfName !== "" &&
      docsData.pdfBase64 !== null &&
      pdfDocSubmitted === false &&
      companyRegistrationDocSent &&
      idCopiesDoc1Sent &&
      idCopiesDoc2Sent &&
      idCopiesDoc3Sent &&
      taxClearanceCertificateDocSent &&
      bankStatementDoc1Sent &&
      bankStatementDoc2Sent &&
      bankStatementDoc3Sent &&
      bankStatementDoc4Sent &&
      bankStatementDoc5Sent &&
      bankStatementDoc6Sent &&
      proofOfBankAccountDocSent &&
      utilityBillDocSent &&
      managementAccountsDocSent &&
      auditedFinanialStatementsDocSent
    ) {
      setPdfDocSubmitted(true);
      const doc = {
        leadId: docsData.leadId,
        pdfName: docsData.pdfName,
        pdfBase64: docsData.pdfBase64,
      };

      axios
        .post(dev ? "http://localhost:5000/api/docs" : "api/docs", doc, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response OK: " + response.status);
              setDocsSent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setDocsError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setDocsError(true);
          }
        );
    }
  }, [
    dev,
    data,
    docsData,
    headers,
    pdfDocSubmitted,
    companyRegistrationDocSent,
    idCopiesDoc1Sent,
    idCopiesDoc2Sent,
    idCopiesDoc3Sent,
    taxClearanceCertificateDocSent,
    bankStatementDoc1Sent,
    bankStatementDoc2Sent,
    bankStatementDoc3Sent,
    bankStatementDoc4Sent,
    bankStatementDoc5Sent,
    bankStatementDoc6Sent,
    proofOfBankAccountDocSent,
    utilityBillDocSent,
    managementAccountsDocSent,
    auditedFinanialStatementsDocSent,
    setDocsSent,
    setDocsError,
  ]);

  const back = () => setDocsDataCaptured(false);
  const clear = () => sigCanvas.current.clear();

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleCheckboxInfoChange = (event) => {
    const { name, checked } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: checked ? "Yes" : "No",
      };
    });
  };

  const addTextToPdf = (text, size, font, x, y, spacing) => {
    doc.setFontSize(size);
    doc.setFont(undefined, font);
    const splitText = doc.splitTextToSize(text, docWidth - 80);
    const textDim = doc.getTextDimensions(splitText);
    const textHeight = textDim.h;

    if (y + textHeight >= docHeight - 20) {
      doc.addPage();
      yPos = 60;

      y = yPos;
    }

    doc.text(splitText, x, y);
    yPos = yPos + textHeight + spacing;
  };

  const addTableToPdf = (
    heading,
    headingSize,
    tableHeader,
    tableData,
    size,
    font,
    x,
    y,
    spacing
  ) => {
    doc.setFontSize(headingSize);
    doc.setFont(undefined, font);

    const splitText = doc.splitTextToSize(heading, docWidth - 80);
    const textDim = doc.getTextDimensions(splitText);
    const textHeight = textDim.h;

    // Calculated table height
    const estimatedTableHeight = tableData.length * 21.5 + 40;

    if (y + estimatedTableHeight + textHeight >= docHeight - 20) {
      doc.addPage();
      yPos = 60;

      y = yPos;
    }
    //Add heading
    doc.setFontSize(headingSize);
    doc.text(splitText, x, y);
    yPos = yPos + textHeight;
    y = yPos;

    doc.setFontSize(size);
    doc.autoTable({
      startY: y,
      columnStyles: {
        0: { cellWidth: 200 },
        1: { cellWidth: "auto" },
      },
      styles: { cellPadding: 5, lineWidth: 1 },
      // head: [tableHeader],
      body: tableData.map((detail) => [detail.title, detail.data]),
      didDrawPage: function (data) {
        yPos = data.cursor.y + spacing;
      },
    });
  };

  const createPdf = () => {
    // Signature
    const sigImage = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    // Logo
    doc.addImage(logoImg, "JPG", 20, 20, logoWidth, logoHeight);

    yPos = logoHeight + 60;

    //Form Name
    addTextToPdf(
      "Business Finance Application Form",
      14,
      "normal",
      200,
      yPos,
      10
    );

    //Applicant Details
    const applicantDetails = [
      { title: "Company Name", data: data.companyName },
      { title: "Trading Name", data: data.tradingName },
      {
        title: "Company Registration Number",
        data: data.companyRegistrationNumber,
      },
      { title: "Size Of Organisation", data: data.sizeOfOrganisation },
      { title: "Tax Number", data: data.taxNumber },
      { title: "VAT Number", data: data.vatNumber },
      { title: "Holding Company", data: data.holdingCompany },
      {
        title: "Holding Company Registration Number",
        data: data.holdingCompanyRegNumber,
      },
      { title: "Industry", data: data.industry },
      { title: "Company Type", data: data.companyType },
      { title: "Name of consultant who assisted you", data: data.consultant },
    ];
    addTableToPdf(
      "Applicant Details",
      12,
      ["Item", "Value"],
      applicantDetails,
      10,
      "normal",
      40,
      yPos,
      40
    );

    //Applicant Contact Details
    const applicantContactDetails = [
      { title: "Contact First Name", data: data.contactFirstName },
      { title: "Contact Last Name", data: data.contactLastName },
      { title: "Business Telephone Number", data: data.telNumber },
      { title: "Business Fax Number", data: data.faxNumber },
      { title: "Alternative Number", data: data.altNumber },
      { title: "Cell Number", data: data.mobile },
      { title: "Email", data: data.email },
    ];
    addTableToPdf(
      "Applicant Contact Details",
      12,
      ["Item", "Value"],
      applicantContactDetails,
      10,
      "normal",
      40,
      yPos,
      40
    );

    //Applicant Delivery Address Details
    const applicantDeliveryAddressDetails = [
      { title: "Unit / Complex", data: data.complex },
      { title: "Street Address", data: data.streetAddress },
      { title: "Suburb", data: data.suburb },
      { title: "City", data: data.city },
      { title: "Province", data: data.province },
      { title: "Postal Code", data: data.postalCode },
      {
        title: "Number of years at residence",
        data: data.numberOfYearsAtResidence,
      },
      { title: "Premises Ownership", data: data.premisesOwnership },
    ];
    addTableToPdf(
      "Applicant Delivery Address Details",
      12,
      ["Item", "Value"],
      applicantDeliveryAddressDetails,
      10,
      "normal",
      40,
      yPos,
      40
    );

    //Applicant Postal Address Details
    const applicantPostalAddressDetails = [
      { title: "Unit / Complex", data: data.postalComplex },
      { title: "Postal Address", data: data.postalAddress },
      { title: "Suburb", data: data.postalSuburb },
      { title: "City", data: data.postalCity },
      { title: "Province", data: data.postalProvince },
      { title: "Postal Code", data: data.postalPostalCode },
    ];
    addTableToPdf(
      "Applicant Postal Address Details",
      12,
      ["Item", "Value"],
      applicantPostalAddressDetails,
      10,
      "normal",
      40,
      yPos,
      40
    );

    //Company Ownership Details
    const director1Details = [
      { title: "Full Name", data: `${data.firstName1} ${data.lastName1}` },
      { title: "ID Number", data: data.idNumber1 },
      { title: "Shareholding %", data: data.shareholding1 },
      { title: "Will Sign Surety", data: data.willSignSurety1 },
    ];
    addTableToPdf(
      "Director 1 Details",
      12,
      ["Item", "Value"],
      director1Details,
      10,
      "normal",
      40,
      yPos,
      40
    );

    if (directors.length > 1) {
      const director2Details = [
        { title: "Full Name", data: `${data.firstName2} ${data.lastName2}` },
        { title: "ID Number", data: data.idNumber2 },
        { title: "Shareholding %", data: data.shareholding2 },
        { title: "Will Sign Surety", data: data.willSignSurety2 },
      ];
      addTableToPdf(
        "Director 2 Details",
        12,
        ["Item", "Value"],
        director2Details,
        10,
        "normal",
        40,
        yPos,
        40
      );
    }

    if (directors.length > 2) {
      const director3Details = [
        { title: "Full Name", data: `${data.firstName3} ${data.lastName3}` },
        { title: "ID Number", data: data.idNumber3 },
        { title: "Shareholding %", data: data.shareholding3 },
        { title: "Will Sign Surety", data: data.willSignSurety3 },
      ];
      addTableToPdf(
        "Director 3 Details",
        12,
        ["Item", "Value"],
        director3Details,
        10,
        "normal",
        40,
        yPos,
        40
      );
    }

    //Financial Details
    const financialDetails = [
      { title: "Name Of Bank", data: data.bankName },
      { title: "Account Number", data: data.accountNumber },
      { title: "Account Type", data: data.accountType },
      { title: "Branch Code", data: data.branchCode },
      { title: "Date Opened", data: data.dateOpened },
      { title: "Overdraft Limit", data: data.overdraftLimit },
      { title: "Net Asset Value", data: data.netAssetValue },
      { title: "Annual Turnover", data: data.annualTurnover },
      { title: "Name Of Auditor", data: data.nameOfAuditor },
      { title: "Auditor Contact Person", data: data.auditorContactPerson },
      { title: "Auditor Telephone Number", data: data.auditorTelNumber },
    ];
    addTableToPdf(
      "Financial Details",
      12,
      ["Item", "Value"],
      financialDetails,
      10,
      "normal",
      40,
      yPos,
      40
    );

    //Rental Period Details
    const rentalPeriodDetails = [
      { title: "Period Required", data: data.periodRequired },
    ];
    addTableToPdf(
      "Rental Period Details",
      12,
      ["Item", "Value"],
      rentalPeriodDetails,
      10,
      "normal",
      40,
      yPos,
      40
    );

    //Add final page
    doc.addPage();
    yPos = 60;

    //Terms and Conditions
    addTextToPdf(
      "Declaration and Permission To Credit Check",
      12,
      "normal",
      35,
      yPos,
      7
    );
    addTextToPdf(declaration1, 10, "normal", 35, yPos, 20);
    addTextToPdf(declaration2, 10, "normal", 35, yPos, 20);
    addTextToPdf(declaration3, 10, "normal", 35, yPos, 20);
    addTextToPdf(declaration4, 10, "normal", 35, yPos, 20);
    addTextToPdf(declaration5, 10, "normal", 35, yPos, 20);
    addTextToPdf(declaration6, 10, "normal", 35, yPos, 20);
    addTextToPdf(declaration7, 10, "normal", 35, yPos, 20);

    //Declaration
    addTextToPdf(
      `Signed at ${data.signedLocation} on ${data.signedDate}`,
      10,
      "normal",
      35,
      yPos,
      5
    );
    addTextToPdf(
      `Name: ${data.applicationCompletedByName} ${data.applicationCompletedByLastName}`,
      10,
      "normal",
      35,
      yPos,
      5
    );
    addTextToPdf(
      `Designation: ${data.applicationCompletedByDesignation}`,
      10,
      "normal",
      35,
      yPos,
      5
    );
    addTextToPdf(`IP Address: ${data.ip}`, 10, "normal", 35, yPos, 5);

    if (data.position.latitude && data.position.longitude) {
      addTextToPdf(
        `Geo Location: Latitude: ${data.position.latitude}, Longitude: ${data.position.longitude}`,
        10,
        "normal",
        35,
        yPos,
        5
      );
    }

    // Signature
    const sigImgProperties = doc.getImageProperties(sigImage);
    let sigHeight =
      (sigImgProperties.height * docWidth) / sigImgProperties.width;
    sigHeight = sigHeight * 0.25;
    doc.addImage(
      sigImage,
      "PNG",
      35,
      yPos + 20,
      (docWidth - 20) * 0.24,
      sigHeight
    );

    // Save PDF to user's device
    doc.save("Finance Application Form.pdf");

    // Convert the PDF document to a Blob
    const pdfBlob = doc.output("blob");

    fileToBase64(pdfBlob, (err, result) => {
      if (result) {
        const base64 = result.replace("data:application/pdf;base64,", "");
        saveFile("Finance Application Form.pdf", base64);
      } else {
        console.log(err);
      }
    });

    submitToLaminin();
  };

  // Convert file to base64 string
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
      cb(error, null);
    };
  };

  const saveFile = (filename, base64) => {
    setDocsData((prevValue) => {
      return {
        ...prevValue,
        pdfName: filename,
        pdfBase64: base64,
      };
    });
    setCount((count) => count + 1);
  };

  const saveLeadId = (leadId) => {
    setData((prevValue) => {
      return {
        ...prevValue,
        leadId: leadId,
      };
    });
    setDocsData((prevValue) => {
      return {
        ...prevValue,
        leadId: leadId,
      };
    });
    setCount((count) => count + 1);
  };

  const submitToLaminin = () => {
    setLamininSubmitted(true);

    if (
      docsData.companyRegistrationName === "" &&
      docsData.companyRegistrationBase64 === null
    ) {
      setCompanyRegistrationDocSubmitted(true);
      setCompanyRegistrationDocSent(true);
    }

    if (docsData.idCopies1Name === "" && docsData.idCopies1Base64 === null) {
      setIdCopiesDoc1Submitted(true);
      setIdCopiesDoc1Sent(true);
    }

    if (docsData.idCopies2Name === "" && docsData.idCopies2Base64 === null) {
      setIdCopiesDoc2Submitted(true);
      setIdCopiesDoc2Sent(true);
    }

    if (docsData.idCopies3Name === "" && docsData.idCopies3Base64 === null) {
      setIdCopiesDoc3Submitted(true);
      setIdCopiesDoc3Sent(true);
    }

    if (
      docsData.taxClearanceCertificateName === "" &&
      docsData.taxClearanceCertificateBase64 === null
    ) {
      setTaxClearanceCertificateDocSubmitted(true);
      setTaxClearanceCertificateDocSent(true);
    }

    if (
      docsData.bankStatement1Name === "" &&
      docsData.bankStatement1Base64 === null
    ) {
      setBankStatementDoc1Submitted(true);
      setBankStatementDoc1Sent(true);
    }

    if (
      docsData.bankStatement2Name === "" &&
      docsData.bankStatement2Base64 === null
    ) {
      setBankStatementDoc2Submitted(true);
      setBankStatementDoc2Sent(true);
    }

    if (
      docsData.bankStatement3Name === "" &&
      docsData.bankStatement3Base64 === null
    ) {
      setBankStatementDoc3Submitted(true);
      setBankStatementDoc3Sent(true);
    }

    if (
      docsData.bankStatement4Name === "" &&
      docsData.bankStatement4Base64 === null
    ) {
      setBankStatementDoc4Submitted(true);
      setBankStatementDoc4Sent(true);
    }

    if (
      docsData.bankStatement5Name === "" &&
      docsData.bankStatement5Base64 === null
    ) {
      setBankStatementDoc5Submitted(true);
      setBankStatementDoc5Sent(true);
    }

    if (
      docsData.bankStatement6Name === "" &&
      docsData.bankStatement6Base64 === null
    ) {
      setBankStatementDoc6Submitted(true);
      setBankStatementDoc6Sent(true);
    }

    if (
      docsData.proofOfBankAccountName === "" &&
      docsData.proofOfBankAccountBase64 === null
    ) {
      setProofOfBankAccountDocSubmitted(true);
      setProofOfBankAccountDocSent(true);
    }

    if (
      docsData.utilityBillName === "" &&
      docsData.utilityBillBase64 === null
    ) {
      setUtilityBillDocSubmitted(true);
      setUtilityBillDocSent(true);
    }

    if (
      docsData.managementAccountsName === "" &&
      docsData.managementAccountsBase64 === null
    ) {
      setManagementAccountsDocSubmitted(true);
      setManagementAccountsDocSent(true);
    }

    if (
      docsData.auditedFinanialStatementsName === "" &&
      docsData.auditedFinanialStatementsBase64 === null
    ) {
      setAuditedFinanialStatementsDocSubmitted(true);
      setAuditedFinanialStatementsDocSent(true);
    }

    if (
      data.leadId === null ||
      data.leadId === "" ||
      data.leadId === undefined
    ) {
      axios
        .post(dev ? "http://localhost:5000/api/create" : "api/create", data, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              setLamininResponse(response.data);
              console.log("Laminin Response OK: " + response.status);
              setLamininSent(true);
              const { NewLeadId } = response.data;
              saveLeadId(NewLeadId);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setLamininError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setLamininError(true);
          }
        );
    } else {
      axios
        .post(dev ? "http://localhost:5000/api/update" : "api/update", data, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              setLamininResponse(response.data);
              console.log("Laminin Response OK: " + response.status);
              setLamininSent(true);
            } else {
              console.log("Laminin Response Not OK: " + response.status);
              setLamininError(true);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
            setLamininError(true);
          }
        );
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Accept Terms and Conditions validation
    if (data.acceptTermsAndConditions !== "Yes") {
      setAcceptTermsError("Please accept the Terms and Conditions to continue");
      isValid = false;
    } else {
      setAcceptTermsError("");
      setFormError("");
    }

    if (
      sigCanvas.current.isEmpty() &&
      data.acceptTermsAndConditions === "Yes"
    ) {
      setSignatureError(
        "Please sign in the area provided and click on Submit once done"
      );
      isValid = false;
    } else {
      setSignatureError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      setDeclarationDataCaptured(true);
      createPdf();
    }
  };

  return (
    <div>
      {!declarationDataCaptured && (
        <Form onSubmit={handleFormSubmit}>
          <FormHeading>
            {props.name} - {props.formName}
          </FormHeading>
          {formError && (
            <ValidationSubHeading>{formError}</ValidationSubHeading>
          )}

          <FormSubHeading>
            Declaration and Permission To Credit Check
          </FormSubHeading>
          <FormBody>
            <ItemWrapper>
              <Label>{intro}</Label>
              <TermsHeading>{heading1}</TermsHeading>
              <Terms>{par11}</Terms>
              <br />
              <TermsHeading>{heading2}</TermsHeading>
              <Terms>{par21}</Terms>
              <Terms>{par22}</Terms>
              <Terms>{par23}</Terms>
              <br />
              <TermsHeading>{heading3}</TermsHeading>
              <Terms>{par31}</Terms>
              <br />
              <CheckBoxButton
                name="acceptTermsAndConditions"
                id="acceptTermsAndConditions"
                onChange={handleCheckboxInfoChange}
                value="Yes"
                checked={data.acceptTermsAndConditions === "Yes"}
                label="I accept the Terms and Conditions"
              />
              {acceptTermsError && (
                <ValidationMessage>{acceptTermsError}</ValidationMessage>
              )}
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Input
                    type="text"
                    name="applicationCompletedByName"
                    onChange={handleInfoChange}
                    placeholder="First Name (Required)"
                    required={true}
                    value={data.applicationCompletedByName}
                    width="100%"
                  />
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Input
                    type="text"
                    name="applicationCompletedByLastName"
                    onChange={handleInfoChange}
                    placeholder="Last Name (Required)"
                    required={true}
                    value={data.applicationCompletedByLastName}
                    width="100%"
                  />
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Input
                    type="text"
                    name="applicationCompletedByDesignation"
                    onChange={handleInfoChange}
                    placeholder="Designation (Required)"
                    required={true}
                    value={data.applicationCompletedByDesignation}
                    width="100%"
                  />
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Input
                    type="text"
                    name="signedLocation"
                    onChange={handleInfoChange}
                    placeholder="Signed Location (Required)"
                    required={true}
                    value={data.signedLocation}
                    width="100%"
                  />
                </Col>
              </Row>
            </ItemWrapper>
            <SignatureWrapper>
              <SignaturePad
                clearSignature={clear}
                ref={sigCanvas}
                canvasProps={{
                  className: "signature",
                }}
              />
            </SignatureWrapper>
            {!signatureError && <MessageCenter>Signature</MessageCenter>}
            {signatureError && (
              <ValidationMessageCenter>
                {signatureError}
              </ValidationMessageCenter>
            )}
            <SignatureButtonWrapper>
              <button
                onClick={back}
                className="btn btn-outline-dark btn-md"
                type="button"
                style={{ margin: "0 2px" }}
              >
                Back
              </button>
              <button
                className="btn btn-outline-dark btn-md"
                type="button"
                style={{ margin: "0 2px" }}
                onClick={clear}
              >
                Clear
              </button>
              <button
                className="btn btn-dark btn-md"
                type="submit"
                style={{ margin: "0 2px" }}
              >
                Submit
              </button>
            </SignatureButtonWrapper>
          </FormBody>
        </Form>
      )}

      {declarationDataCaptured &&
        lamininSubmitted &&
        !((lamininSent && docsSent) || lamininError || docsError) && (
          <Form>
            <FormHeading>
              {props.name} - {props.formName}
            </FormHeading>
            <FormSubHeading>Declaration</FormSubHeading>
            <FormSubHeading>Processing...</FormSubHeading>
            <FormSubHeading>
              Submitting your information, please stand by...
            </FormSubHeading>
          </Form>
        )}

      {declarationDataCaptured && lamininSent && docsSent && (
        <Form>
          <FormHeading>
            {props.name} - {props.formName}
          </FormHeading>
          <FormSubHeading>Declaration</FormSubHeading>
          <FormSubHeading>
            Thank you for submitting your information, your Application
            Reference is {data.leadId}. One of our representatives will be in
            contact with you shortly.
          </FormSubHeading>
        </Form>
      )}

      {declarationDataCaptured && (lamininError || docsError) && (
        <Form>
          <FormHeading>
            {props.name} - {props.formName}
          </FormHeading>
          <FormSubHeading>Declaration</FormSubHeading>
          <FormSubHeading>Something went wrong</FormSubHeading>
          <FormSubHeading>
            We're sorry, something went wrong with your application.
            Unfortunately, we were unable to process your information at this
            time. Please reach out to us so we can assist you with your
            application. You can contact us by emailing {props.email} or by
            calling us at {props.phone}. Thank you for your patience, and we
            apologise for the inconvenience.
          </FormSubHeading>
        </Form>
      )}
      <Hidden>{count}</Hidden>
    </div>
  );
}
