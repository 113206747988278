import styled from "styled-components";

export const Label = styled.label`
  color: ${(props) => props.theme.colors.body};
  font-size: medium;
  font-family: ${({ theme }) => theme.font.body};

  &:hover {
    cursor: pointer;
  }
`;

export const Input = styled.input`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};

  &:hover {
    cursor: pointer;
  }
`;
