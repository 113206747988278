import styled from "styled-components";

export const Form = styled.form`
  --shadow-color: ${({ shadow }) => shadow || "0deg 0% 50%"};
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: ${({ border }) => border || "none"};
  box-shadow: ${({ theme }) => theme.elevations.medium};
  background-color: ${({ bg }) => bg || "#fff"};
  padding: ${({ theme }) => theme.space[4]};
`;
