//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Data
import { BankDataFull } from "../../data/BankDataFull";

//Styled Components
import { Col } from "../styles/Col";
import { Dropdown } from "../styles/Dropdown.styled";
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";
import { Row } from "../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function CompanyFinancials(props) {
  const {
    data,
    setData,
    formError,
    setFormError,
    accountNumberError,
    setAccountNumberError,
    auditorTelNumberError,
    setAuditorTelNumberError,
    setCompanyOwnershipDataCaptured,
    setCompanyFinancialsDataCaptured,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setCompanyOwnershipDataCaptured(false);

  const handleBankInfoChange = (event) => {
    const { name, value } = event.target;
    BankDataFull.forEach((bank) => {
      if (bank.Name === value) {
        setData((prevValue) => {
          return {
            ...prevValue,
            [name]: value,
            branchCode: bank.BranchCode,
          };
        });
      }
    });
  };

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Account number validation
    if (data.accountNumber.length === 0 || isNaN(data.accountNumber)) {
      setAccountNumberError("Please enter a Account Number without any spaces");
      isValid = false;
    } else {
      setAccountNumberError("");
      setFormError("");
    }

    // Auditor Telephone number validation
    if (data.auditorTelNumber.length !== 10 || isNaN(data.auditorTelNumber)) {
      setAuditorTelNumberError(
        "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
      );
      isValid = false;
    } else {
      setAuditorTelNumberError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      setCompanyFinancialsDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} autocomplete="off">
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>
        Complete your Company Financial Details below to continue your
        application process
      </FormSubHeading>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <FormSubHeading>Financial Details</FormSubHeading>
        <ItemWrapper>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Name Of Bank (Required)</Label>
              <Dropdown
                name="bankName"
                onChange={handleBankInfoChange}
                required={true}
                value={data.bankName}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                {BankDataFull.map((bank, index) => {
                  return (
                    <option value={bank.Name} key={index}>
                      {bank.Name}
                    </option>
                  );
                })}
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Account Number (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="accountNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.accountNumber}
                width="100%"
              />
              {accountNumberError && (
                <ValidationMessage>{accountNumberError}</ValidationMessage>
              )}
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Account Type (Required)</Label>
              <Dropdown
                name="accountType"
                onChange={handleInfoChange}
                required={true}
                value={data.accountType}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Cheque Account">Cheque Account</option>
                <option value="Savings Account">Savings Account</option>
                <option value="Current Account">Current Account</option>
                <option value="Transmission Account">
                  Transmission Account
                </option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Branch Code (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="branchCode"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.branchCode}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Date Opened (Required)</Label>
              <Input
                type="date"
                name="dateOpened"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.dateOpened}
                width="100%"
                max="9999-12-31"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Overdraft Limit (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="overdraftLimit"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.overdraftLimit}
                width="100%"
              />
            </Col>

            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Net Asset Value (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="netAssetValue"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.netAssetValue}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Annual Turnover (Required)</Label>
              <Dropdown
                name="annualTurnover"
                onChange={handleInfoChange}
                required={true}
                value={data.annualTurnover}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="R1 million - R2 million">
                  R1 million - R2 million
                </option>
                <option value="R2 million - R5million">
                  R2 million - R5million
                </option>
                <option value="R5 million +">R5 million +</option>
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Name Of Auditor (Required)</Label>
              <Input
                type="text"
                name="nameOfAuditor"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.nameOfAuditor}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Auditor Contact Person (Required)</Label>
              <Input
                type="text"
                name="auditorContactPerson"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.auditorContactPerson}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Auditor Telephone Number (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="auditorTelNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.auditorTelNumber}
                width="100%"
                maxLength={10}
              />
              {auditorTelNumberError && (
                <ValidationMessage>{auditorTelNumberError}</ValidationMessage>
              )}
            </Col>
          </Row>
        </ItemWrapper>
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
