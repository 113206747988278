export const ProvinceData = [
  {
    name: "Eastern Cape",
  },
  {
    name: "Free State",
  },
  {
    name: "Gauteng",
  },
  {
    name: "KwaZulu-Natal",
  },
  {
    name: "Limpopo",
  },
  {
    name: "Mpumalanga",
  },
  {
    name: "Northern Cape",
  },
  {
    name: "North West",
  },
  {
    name: "Western Cape",
  },
];
