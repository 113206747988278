import styled from "styled-components";

export const Table = styled.table`
  padding: ${({ theme }) => theme.space[0]};
  width: 100%;
`;

export const Thead = styled.thead`
  padding: ${({ theme }) => theme.space[0]};
  font-size: medium;

  @media ${({ theme }) => theme.deviceMax.tablet} {
    font-size: small;
  }
`;

export const Tbody = styled.tbody`
  padding: ${({ theme }) => theme.space[0]};
  font-size: small;

  @media ${({ theme }) => theme.deviceMax.tablet} {
    font-size: 10px;
  }
`;

export const Tr = styled.tr``;

export const Th = styled.th``;

export const Td = styled.td`
  text-align: left;
`;
