export const OccupationIndustry = [
  {
    code: 1,
    name: "Agriculture",
  },
  {
    code: 2,
    name: "Catering and Accommodation",
  },
  {
    code: 3,
    name: "Civil Service",
  },
  {
    code: 4,
    name: "Community Services",
  },
  {
    code: 5,
    name: "Construction",
  },
  {
    code: 6,
    name: "Education",
  },
  {
    code: 7,
    name: "Engineering",
  },
  {
    code: 8,
    name: "Finance",
  },
  {
    code: 9,
    name: "Imports and Exports",
  },
  {
    code: 10,
    name: "Industrial",
  },
  {
    code: 11,
    name: "Information Technology",
  },
  {
    code: 12,
    name: "Legal",
  },
  {
    code: 13,
    name: "Media and Advertising",
  },
  {
    code: 14,
    name: "Medical",
  },
  {
    code: 24,
    name: "Mining",
  },
  {
    code: 15,
    name: "National Forces",
  },
  {
    code: 16,
    name: "Natural Resources",
  },
  {
    code: 17,
    name: "Personal Services",
  },
  {
    code: 18,
    name: "Science",
  },
  {
    code: 19,
    name: "Security",
  },
  {
    code: 20,
    name: "Selling",
  },
  {
    code: 21,
    name: "Sports and Entertainment",
  },
  {
    code: 22,
    name: "Transportation",
  },
  {
    code: 23,
    name: "Welfare",
  },
  {
    code: 25,
    name: "Other",
  },
];
