//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Col } from "../styles/Col";
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";
import RadioButton from "../styles/RadioButton";
import { Row } from "../styles/Row";
import Director from "./Director";
import { Table, Thead, Tr, Th } from "../styles/Table.styled";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.body};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  @media ${({ theme }) => theme.device.laptop} {
    flex-direction: row;
  }
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationMessageCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function CompanyOwnership(props) {
  const {
    data,
    setData,
    directors,
    setDirectors,
    directorsError,
    setDirectorsError,
    formError,
    setFormError,
    firstNameError,
    setFirstNameError,
    lastNameError,
    setLastNameError,
    idNumberError,
    setIdNumberError,
    shareholdingError,
    setShareholdingError,
    suretyError,
    setSuretyError,
    setApplicantAddressDataCaptured,
    setCompanyOwnershipDataCaptured,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setApplicantAddressDataCaptured(false);

  const addDirector = () => {
    setDirectorsError("");
    setFormError("");
    let isValid = true;

    //Directors First Name validation
    if (data.firstName === "") {
      setFirstNameError("Please enter the Director's First Name");
      isValid = false;
    } else {
      setFirstNameError("");
      setFormError("");
    }

    //Directors Last Name validation
    if (data.lastName === "") {
      setLastNameError("Please enter the Director's Last Name");
      isValid = false;
    } else {
      setLastNameError("");
      setFormError("");
    }

    //Directors ID Number validation
    if (data.idNumber === "") {
      setIdNumberError("Please enter the Director's ID Number");
      isValid = false;
    } else {
      setIdNumberError("");
      setFormError("");
    }

    //Directors Shareholding validation
    if (data.shareholding === "") {
      setShareholdingError("Please enter the Director's Shareholding %");
      isValid = false;
    } else {
      setShareholdingError("");
      setFormError("");
    }

    //Directors Surety validation
    if (data.willSignSurety === "") {
      setSuretyError("Please indicate if the Director's will sign surety");
      isValid = false;
    } else {
      setSuretyError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      directors.push({
        firstName: data.firstName,
        lastName: data.lastName,
        idNumber: data.idNumber,
        shareholding: data.shareholding,
        willSignSurety: data.willSignSurety,
      });
      setData((prevValue) => {
        return {
          ...prevValue,
          firstName: "",
          lastName: "",
          idNumber: "",
          shareholding: "",
          willSignSurety: "",
        };
      });
    }
  };

  const deleteDirector = (id) => {
    setDirectors((prevValue) => {
      return prevValue.filter((director, index) => {
        return index !== id;
      });
    });
  };

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    //Directors validation
    if (directors.length === 0) {
      setDirectorsError("Please enter the details of at least one director");
      isValid = false;
    } else {
      setDirectorsError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      if (directors.length === 1) {
        setData((prevValue) => {
          return {
            ...prevValue,
            firstName1: directors[0].firstName,
            lastName1: directors[0].lastName,
            idNumber1: directors[0].idNumber,
            shareholding1: directors[0].shareholding,
            willSignSurety1: directors[0].willSignSurety,
            firstName2: "",
            lastName2: "",
            idNumber2: "",
            shareholding2: "",
            willSignSurety2: "",
            firstName3: "",
            lastName3: "",
            idNumber3: "",
            shareholding3: "",
            willSignSurety3: "",
          };
        });
      }

      if (directors.length === 2) {
        setData((prevValue) => {
          return {
            ...prevValue,
            firstName1: directors[0].firstName,
            lastName1: directors[0].lastName,
            idNumber1: directors[0].idNumber,
            shareholding1: directors[0].shareholding,
            willSignSurety1: directors[0].willSignSurety,
            firstName2: directors[1].firstName,
            lastName2: directors[1].lastName,
            idNumber2: directors[1].idNumber,
            shareholding2: directors[1].shareholding,
            willSignSurety2: directors[1].willSignSurety,
            firstName3: "",
            lastName3: "",
            idNumber3: "",
            shareholding3: "",
            willSignSurety3: "",
          };
        });
      }

      if (directors.length === 3) {
        setData((prevValue) => {
          return {
            ...prevValue,
            firstName1: directors[0].firstName,
            lastName1: directors[0].lastName,
            idNumber1: directors[0].idNumber,
            shareholding1: directors[0].shareholding,
            willSignSurety1: directors[0].willSignSurety,
            firstName2: directors[1].firstName,
            lastName2: directors[1].lastName,
            idNumber2: directors[1].idNumber,
            shareholding2: directors[1].shareholding,
            willSignSurety2: directors[1].willSignSurety,
            firstName3: directors[2].firstName,
            lastName3: directors[2].lastName,
            idNumber3: directors[2].idNumber,
            shareholding3: directors[2].shareholding,
            willSignSurety3: directors[2].willSignSurety,
          };
        });
      }
      setCompanyOwnershipDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} autocomplete="off">
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>
        Complete your Company Ownership Details below to continue your
        application process
      </FormSubHeading>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <FormSubHeading>Company Ownership Details</FormSubHeading>
        <ItemWrapper>
          <Label>Director</Label>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>First Name</Label>
              <Input
                type="text"
                name="firstName"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.firstName}
                width="100%"
                disabled={directors.length === 3}
              />
              {firstNameError && (
                <ValidationMessage>{firstNameError}</ValidationMessage>
              )}
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Last Name</Label>
              <Input
                type="text"
                name="lastName"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.lastName}
                width="100%"
                disabled={directors.length === 3}
              />
              {lastNameError && (
                <ValidationMessage>{lastNameError}</ValidationMessage>
              )}
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>ID Number</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="idNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.idNumber}
                width="100%"
                maxLength={13}
                disabled={directors.length === 3}
              />
              {idNumberError && (
                <ValidationMessage>{idNumberError}</ValidationMessage>
              )}
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Shareholding %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="shareholding"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.shareholding}
                width="100%"
                disabled={directors.length === 3}
              />
              {shareholdingError && (
                <ValidationMessage>{shareholdingError}</ValidationMessage>
              )}
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Will Sign Surety</Label>
              <RadioButtonWrapper>
                <RadioButton
                  name="willSignSurety"
                  id="Yes"
                  onChange={handleInfoChange}
                  value="Yes"
                  checked={data.willSignSurety === "Yes"}
                  label="Yes"
                  required={false}
                  disabled={directors.length === 3}
                />
                <RadioButton
                  onChange={handleInfoChange}
                  name="willSignSurety"
                  id="No"
                  value="No"
                  checked={data.willSignSurety === "No"}
                  label="No"
                  required={false}
                  disabled={directors.length === 3}
                />
              </RadioButtonWrapper>
              {suretyError && (
                <ValidationMessage>{suretyError}</ValidationMessage>
              )}
            </Col>
          </Row>
        </ItemWrapper>

        <ButtonWrapper>
          <button
            onClick={addDirector}
            className="btn btn-dark btn-md"
            type="button"
            style={{ margin: "0 2px" }}
          >
            Add Director
          </button>
        </ButtonWrapper>

        {directorsError && (
          <ValidationMessageCenter>{directorsError}</ValidationMessageCenter>
        )}
        {directors.length === 3 && (
          <ValidationMessageCenter>
            Maximum number of Directors reached. Additional directors can be
            captured on the full agreement.
          </ValidationMessageCenter>
        )}

        <hr />
        <ItemWrapper>
          <Table>
            <Thead>
              <Tr>
                <Th>Full Name</Th>
                <Th>Surety</Th>
                <Th></Th>
              </Tr>
            </Thead>

            {directors.map((director, index) => {
              return (
                <Director
                  key={index}
                  id={index}
                  fullName={`${director.firstName} ${director.lastName}`}
                  idNumber={director.idNumber}
                  shareholding={director.shareholding}
                  willSignSurety={director.willSignSurety}
                  onDelete={deleteDirector}
                />
              );
            })}
          </Table>
        </ItemWrapper>
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
