import React from "react";
import styled from "styled-components";
import { MdRemoveCircle } from "react-icons/md";

//Components
import { Tbody, Tr, Td } from "../styles/Table.styled";

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: red;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  margin: 2px;
`;

export default function Director(props) {
  function handleClick() {
    props.onDelete(props.id);
  }

  return (
    <Tbody>
      <Tr>
        <Td>{props.fullName}</Td>
        <Td>{props.willSignSurety}</Td>
        <Td>
          <Button type="button" onClick={handleClick}>
            <MdRemoveCircle />
          </Button>
        </Td>
      </Tr>
    </Tbody>
  );
}
