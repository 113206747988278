//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Data
import { ProvinceData } from "../../data/ProvinceData";

//Styled Components
import CheckboxButton from "../styles/CheckBoxButton";
import { Col } from "../styles/Col";
import { Dropdown } from "../styles/Dropdown.styled";
import { Form } from "../styles/Form.styled";
import GoogleAddress from "../Shared/GoogleAddress";
import { Input } from "../styles/Input.styled";
import { Row } from "../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function ApplicantAddress(props) {
  const {
    data,
    setData,
    formError,
    setFormError,
    postalCodeError,
    setPostalCodeError,
    postalPostalCodeError,
    setPostalPostalCodeError,
    setApplicantContactDetailsDataCaptured,
    setApplicantAddressDataCaptured,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setApplicantContactDetailsDataCaptured(false);

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleCheckboxInfoChange = (event) => {
    const { name, checked } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: checked ? "Yes" : "No",
      };
    });
    if (checked) {
      setData((prevValue) => {
        return {
          ...prevValue,
          postalComplex: data.complex,
          postalAddress: data.streetAddress,
          postalSuburb: data.suburb,
          postalCity: data.city,
          postalProvince: data.province,
          postalPostalCode: data.postalCode,
        };
      });
    } else {
      setData((prevValue) => {
        return {
          ...prevValue,
          complex: "",
          postalAddress: "",
          postalSuburb: "",
          postalCity: "",
          postalProvince: "",
          postalPostalCode: "",
        };
      });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Postal code validation
    if (data.postalCode.length !== 4 || isNaN(data.postalCode)) {
      setPostalCodeError(
        "Please enter a valid 4-digit Postal Code without any spaces"
      );
      isValid = false;
    } else {
      setPostalCodeError("");
      setFormError("");
    }

    if (data.postalPostalCode) {
      if (data.postalPostalCode.length !== 4 || isNaN(data.postalPostalCode)) {
        setPostalPostalCodeError(
          "Please enter a valid 4-digit Postal Code without any spaces"
        );
        isValid = false;
      } else {
        setPostalPostalCodeError("");
        setFormError("");
      }
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      setApplicantAddressDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} autocomplete="off">
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>
        Complete your company address details below to continue your application
        process.
      </FormSubHeading>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <FormSubHeading>Applicant Address Details</FormSubHeading>
        <ItemWrapper>
          <FormLabel>Google Address Search</FormLabel>
          <GoogleAddress />
        </ItemWrapper>
        <ItemWrapper>
          <FormLabel>Delivery Address Information</FormLabel>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Unit / Complex</Label>
              <Input
                type="text"
                name="complex"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.complex}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Street Address (Required)</Label>
              <Input
                type="text"
                name="streetAddress"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.streetAddress}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Suburb (Required)</Label>
              <Input
                type="text"
                name="suburb"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.suburb}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>City (Required)</Label>
              <Input
                type="text"
                name="city"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.city}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Province (Required)</Label>
              <Dropdown
                name="province"
                onChange={handleInfoChange}
                required={true}
                value={data.province}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                {ProvinceData.map((province, index) => {
                  return (
                    <option value={province.name} key={index}>
                      {province.name}
                    </option>
                  );
                })}
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Postal Code (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="postalCode"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.postalCode}
                width="100%"
              />
              {postalCodeError && (
                <ValidationMessage>{postalCodeError}</ValidationMessage>
              )}
            </Col>
          </Row>
        </ItemWrapper>
        <CheckboxButton
          name="physicalPostalSame"
          id="physicalPostalSame"
          onChange={handleCheckboxInfoChange}
          value="Yes"
          checked={data.physicalPostalSame === "Yes"}
          label="Is the Postal Address the same as the Physical Address?"
        />
        <ItemWrapper>
          <FormLabel>Postal Address Information</FormLabel>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Unit / Complex</Label>
              <Input
                type="text"
                name="postalComplex"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.postalComplex}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Postal Address</Label>
              <Input
                type="text"
                name="postalAddress"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.postalAddress}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Suburb</Label>
              <Input
                type="text"
                name="postalSuburb"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.postalSuburb}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>City</Label>
              <Input
                type="text"
                name="postalCity"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.postalCity}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Province</Label>
              <Dropdown
                name="postalProvince"
                onChange={handleInfoChange}
                required={false}
                value={data.postalProvince}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                {ProvinceData.map((province, index) => {
                  return (
                    <option value={province.name} key={index}>
                      {province.name}
                    </option>
                  );
                })}
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Postal Code</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="postalPostalCode"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.postalPostalCode}
                width="100%"
              />
              {postalPostalCodeError && (
                <ValidationMessage>{postalPostalCodeError}</ValidationMessage>
              )}
            </Col>
          </Row>
        </ItemWrapper>
        <ItemWrapper>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Number of years at residence (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="numberOfYearsAtResidence"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.numberOfYearsAtResidence}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Premises Ownership (Required)</Label>
              <Dropdown
                name="premisesOwnership"
                onChange={handleInfoChange}
                required={true}
                value={data.premisesOwnership}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Renting">Renting</option>
                <option value="Owner">Owner</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Name of consultant who assisted you</Label>
              <Input
                type="text"
                name="consultant"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.consultant}
                width="100%"
              />
            </Col>
          </Row>
        </ItemWrapper>
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
