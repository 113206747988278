import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

const Logo = styled.img`
  width: 204px;
  border-radius: 10px;
  padding: ${(props) => props.theme.space[1]};
  @media ${({ theme }) => theme.device.mobileL} {
    padding: ${(props) => props.theme.space[2]};
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding: ${(props) => props.theme.space[3]};
  }
`;

export default function Header(props) {
  return (
    <div>
      <Helmet>
        <title>{props.name}</title>
        <meta name="description" description={props.description} />
      </Helmet>
      <Heading>
        <Logo src={props.logoPath} />
      </Heading>
    </div>
  );
}
