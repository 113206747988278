//NPM Packages
import { React, useContext, useEffect, useState } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Form } from "./../styles/Form.styled";
import { Input } from "./../styles/Input.styled";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const Label = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

const InputWrapper = styled.div`
  padding: 0 1% 1% 0;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function Documents(props) {
  const {
    data,
    setData,
    docsData,
    setDocsData,
    
    //Docs Data
    // companyRegistrationDoc,
    // setCompanyRegistrationDoc,
    // setIdCopiesDoc1,
    // setIdCopiesDoc2,
    // setIdCopiesDoc3,
    // taxClearanceCertificateDoc,
    // setTaxClearanceCertificateDoc,
    // bankStatementDoc1,
    // setBankStatementDoc1,
    // bankStatementDoc2,
    // setBankStatementDoc2,
    // bankStatementDoc3,
    // setBankStatementDoc3,
    // setBankStatementDoc4,
    // setBankStatementDoc5,
    // setBankStatementDoc6,
    // setProofOfBankAccountDoc,
    // utilityBillDoc,
    // setUtilityBillDoc,
    // setManagementAccountsDoc,
    // setAuditedFinanialStatementsDoc,

    directors,
    formError,
    setRentalPeriodDataCaptured,
    setDocsDataCaptured,
  } = useContext(MyContext);

  const [companyRegistrationError, setCompanyRegistrationError] = useState("");
  const [idCopies1Error, setIdCopies1Error] = useState("");
  const [idCopies2Error, setIdCopies2Error] = useState("");
  const [idCopies3Error, setIdCopies3Error] = useState("");
  const [taxClearanceCertificateError, setTaxClearanceCertificateError] =
    useState("");
  const [bankStatement1Error, setBankStatement1Error] = useState("");
  const [bankStatement2Error, setBankStatement2Error] = useState("");
  const [bankStatement3Error, setBankStatement3Error] = useState("");
  const [bankStatement4Error, setBankStatement4Error] = useState("");
  const [bankStatement5Error, setBankStatement5Error] = useState("");
  const [bankStatement6Error, setBankStatement6Error] = useState("");
  const [proofOfBankAccountError, setProofOfBankAccountError] = useState("");
  const [utilityBillError, setUtilityBillError] = useState("");
  const [managementAccountsError, setManagementAccountsError] = useState("");
  const [auditedFinanialStatementsError, setAuditedFinanialStatementsError] =
    useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => {
    clearFiles();
    setRentalPeriodDataCaptured(false);
  };

  const clearFiles = () => {
    setData((prevValue) => {
      return {
        ...prevValue,
        companyRegistration: "",
        idCopies1: "",
        idCopies2: "",
        idCopies3: "",
        taxClearanceCertificate: "",
        bankStatement1: "",
        bankStatement2: "",
        bankStatement3: "",
        bankStatement4: "",
        bankStatement5: "",
        bankStatement6: "",
        proofOfBankAccount: "",
        utilityBill: "",
        managementAccounts: "",
        auditedFinanialStatements: "",
      };
    });
  };

  const handleCompanyRegistrationChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setCompanyRegistrationError("Please select a file smaller than 5MB");
    } else {
      setCompanyRegistrationError("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              companyRegistrationName: fileName,
              companyRegistrationBase64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleIdCopies1Change = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setIdCopies1Error("Please select a file smaller than 5MB");
    } else {
      setIdCopies1Error("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              idCopies1Name: fileName,
              idCopies1Base64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleIdCopies2Change = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setIdCopies2Error("Please select a file smaller than 5MB");
    } else {
      setIdCopies2Error("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              idCopies2Name: fileName,
              idCopies2Base64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleIdCopies3Change = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setIdCopies3Error("Please select a file smaller than 5MB");
    } else {
      setIdCopies3Error("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              idCopies3Name: fileName,
              idCopies3Base64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleTaxClearanceCertificateChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setTaxClearanceCertificateError("Please select a file smaller than 5MB");
    } else {
      setTaxClearanceCertificateError("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              taxClearanceCertificateName: fileName,
              taxClearanceCertificateBase64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleBankStatement1Change = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setBankStatement1Error("Please select a file smaller than 5MB");
    } else {
      setBankStatement1Error("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              bankStatement1Name: fileName,
              bankStatement1Base64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleBankStatement2Change = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setBankStatement2Error("Please select a file smaller than 5MB");
    } else {
      setBankStatement2Error("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              bankStatement2Name: fileName,
              bankStatement2Base64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleBankStatement3Change = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setBankStatement3Error("Please select a file smaller than 5MB");
    } else {
      setBankStatement3Error("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              bankStatement3Name: fileName,
              bankStatement3Base64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleBankStatement4Change = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setBankStatement4Error("Please select a file smaller than 5MB");
    } else {
      setBankStatement4Error("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              bankStatement4Name: fileName,
              bankStatement4Base64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleBankStatement5Change = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setBankStatement5Error("Please select a file smaller than 5MB");
    } else {
      setBankStatement5Error("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              bankStatement5Name: fileName,
              bankStatement5Base64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleBankStatement6Change = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setBankStatement6Error("Please select a file smaller than 5MB");
    } else {
      setBankStatement6Error("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              bankStatement6Name: fileName,
              bankStatement6Base64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleProofOfBankAccountChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setProofOfBankAccountError("Please select a file smaller than 5MB");
    } else {
      setProofOfBankAccountError("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              proofOfBankAccountName: fileName,
              proofOfBankAccountBase64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleUtilityBillChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setUtilityBillError("Please select a file smaller than 5MB");
    } else {
      setUtilityBillError("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              utilityBillName: fileName,
              utilityBillBase64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleManagementAccountsChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setManagementAccountsError("Please select a file smaller than 5MB");
    } else {
      setManagementAccountsError("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              managementAccountsName: fileName,
              managementAccountsBase64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  const handleAuditedFinanialStatementsChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    if (file.size > 5242880) {
      setAuditedFinanialStatementsError(
        "Please select a file smaller than 5MB"
      );
    } else {
      setAuditedFinanialStatementsError("");
      fileToBase64(file, (err, result) => {
        if (result) {
          const base64 = result.replace(`data:${file.type};base64,`, "");
          setDocsData((prevValue) => {
            return {
              ...prevValue,
              auditedFinanialStatementsName: fileName,
              auditedFinanialStatementsBase64: base64,
            };
          });
        } else {
          console.log(err);
        }
      });
    }
  };

  // Convert file to base64 string
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
      cb(error, null);
    };
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setDocsDataCaptured(true);

    clearFiles();
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>
        Please upload documents below to continue your application process
      </FormSubHeading>
      <FormSubHeading>Document Upload</FormSubHeading>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}

      {data.leadId ? (
        <FormBody>
          <ItemWrapper>
            <Label>Supporting Documents</Label>
            <Label>Copy of company registration documents CM1/CM</Label>
            <InputWrapper>
              <Input
                type="file"
                name="companyRegistration"
                onChange={handleCompanyRegistrationChange}
                required={false}
                value={data.companyRegistration}
                width="100%"
              />
              {companyRegistrationError && (
                <ValidationMessage>
                  {companyRegistrationError}
                </ValidationMessage>
              )}
            </InputWrapper>

            <Label>Director 1 Copy of ID</Label>
            <InputWrapper>
              <Input
                type="file"
                name="idCopies1"
                onChange={handleIdCopies1Change}
                required={false}
                value={data.idCopies1}
                width="100%"
              />
              {idCopies1Error && (
                <ValidationMessage>{idCopies1Error}</ValidationMessage>
              )}
            </InputWrapper>
            {directors.length > 1 && (
              <div>
                <Label>Director 2 Copy of ID</Label>
                <InputWrapper>
                  <Input
                    type="file"
                    name="idCopies2"
                    onChange={handleIdCopies2Change}
                    required={false}
                    value={data.idCopies2}
                    width="100%"
                  />
                  {idCopies2Error && (
                    <ValidationMessage>{idCopies2Error}</ValidationMessage>
                  )}
                </InputWrapper>
              </div>
            )}
            {directors.length > 2 && (
              <div>
                <Label>Director 3 Copy of ID</Label>
                <InputWrapper>
                  <Input
                    type="file"
                    name="idCopies3"
                    onChange={handleIdCopies3Change}
                    required={false}
                    value={data.idCopies3}
                    width="100%"
                  />
                  {idCopies3Error && (
                    <ValidationMessage>{idCopies3Error}</ValidationMessage>
                  )}
                </InputWrapper>
              </div>
            )}

            <Label>Copy of valid Tax Clearance Certificate</Label>
            <InputWrapper>
              <Input
                type="file"
                name="taxClearanceCertificate"
                onChange={handleTaxClearanceCertificateChange}
                required={false}
                value={data.taxClearanceCertificate}
                width="100%"
              />
              {taxClearanceCertificateError && (
                <ValidationMessage>
                  {taxClearanceCertificateError}
                </ValidationMessage>
              )}
            </InputWrapper>

            <Label>
              Proof of Address (Copy of Utility Bill or Lease Agreement
              reflecting Business Address)
            </Label>
            <InputWrapper>
              <Input
                type="file"
                name="utilityBill"
                onChange={handleUtilityBillChange}
                required={false}
                value={data.utilityBill}
                width="100%"
              />
              {utilityBillError && (
                <ValidationMessage>{utilityBillError}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Copy of latest Management Accounts</Label>
            <InputWrapper>
              <Input
                type="file"
                name="managementAccounts"
                onChange={handleManagementAccountsChange}
                required={false}
                value={data.managementAccounts}
                width="100%"
              />
              {managementAccountsError && (
                <ValidationMessage>{managementAccountsError}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Copy of latest Audited Financial Statements</Label>
            <InputWrapper>
              <Input
                type="file"
                name="auditedFinanialStatements"
                onChange={handleAuditedFinanialStatementsChange}
                required={false}
                value={data.auditedFinanialStatements}
                width="100%"
              />
              {auditedFinanialStatementsError && (
                <ValidationMessage>
                  {auditedFinanialStatementsError}
                </ValidationMessage>
              )}
            </InputWrapper>
          </ItemWrapper>

          <ItemWrapper>
            <Label>Last 3 Months Bank Statements of Business Account </Label>
            <Label>Month 1</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement1"
                onChange={handleBankStatement1Change}
                required={false}
                value={data.bankStatement1}
                width="100%"
              />
              {bankStatement1Error && (
                <ValidationMessage>{bankStatement1Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 2</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement2"
                onChange={handleBankStatement2Change}
                required={false}
                value={data.bankStatement2}
                width="100%"
              />
              {bankStatement2Error && (
                <ValidationMessage>{bankStatement2Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 3</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement3"
                onChange={handleBankStatement3Change}
                required={false}
                value={data.bankStatement3}
                width="100%"
              />
              {bankStatement3Error && (
                <ValidationMessage>{bankStatement3Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 4</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement4"
                onChange={handleBankStatement4Change}
                required={false}
                value={data.bankStatement4}
                width="100%"
              />
              {bankStatement4Error && (
                <ValidationMessage>{bankStatement4Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 5</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement5"
                onChange={handleBankStatement5Change}
                required={false}
                value={data.bankStatement5}
                width="100%"
              />
              {bankStatement5Error && (
                <ValidationMessage>{bankStatement5Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 6</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement6"
                onChange={handleBankStatement6Change}
                required={false}
                value={data.bankStatement6}
                width="100%"
              />
              {bankStatement6Error && (
                <ValidationMessage>{bankStatement6Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>
              Proof of Business Bank Account not older than 3 Months (not
              applicable where bank statements were provided)
            </Label>
            <InputWrapper>
              <Input
                type="file"
                name="proofOfBankAccount"
                onChange={handleProofOfBankAccountChange}
                required={false}
                value={data.proofOfBankAccount}
                width="100%"
              />
              {proofOfBankAccountError && (
                <ValidationMessage>{proofOfBankAccountError}</ValidationMessage>
              )}
            </InputWrapper>
          </ItemWrapper>
        </FormBody>
      ) : (
        <FormBody>
          <ItemWrapper>
            <Label>Supporting Documents</Label>
            <Label>
              Copy of company registration documents CM1/CM (Required)
            </Label>
            <InputWrapper>
              <Input
                type="file"
                name="companyRegistration"
                onChange={handleCompanyRegistrationChange}
                required={docsData.companyRegistrationBase64 === null}
                value={data.companyRegistration}
                width="100%"
              />
              {companyRegistrationError && (
                <ValidationMessage>
                  {companyRegistrationError}
                </ValidationMessage>
              )}
            </InputWrapper>

            <Label>Director 1 Copy of ID</Label>
            <InputWrapper>
              <Input
                type="file"
                name="idCopies1"
                onChange={handleIdCopies1Change}
                required={false}
                value={data.idCopies1}
                width="100%"
              />
              {idCopies1Error && (
                <ValidationMessage>{idCopies1Error}</ValidationMessage>
              )}
            </InputWrapper>
            {directors.length > 1 && (
              <div>
                <Label>Director 2 Copy of ID</Label>
                <InputWrapper>
                  <Input
                    type="file"
                    name="idCopies2"
                    onChange={handleIdCopies2Change}
                    required={false}
                    value={data.idCopies2}
                    width="100%"
                  />
                  {idCopies2Error && (
                    <ValidationMessage>{idCopies2Error}</ValidationMessage>
                  )}
                </InputWrapper>
              </div>
            )}
            {directors.length > 2 && (
              <div>
                <Label>Director 3 Copy of ID</Label>
                <InputWrapper>
                  <Input
                    type="file"
                    name="idCopies3"
                    onChange={handleIdCopies3Change}
                    required={false}
                    value={data.idCopies3}
                    width="100%"
                  />
                  {idCopies3Error && (
                    <ValidationMessage>{idCopies3Error}</ValidationMessage>
                  )}
                </InputWrapper>
              </div>
            )}

            <Label>Copy of valid Tax Clearance Certificate (Required)</Label>
            <InputWrapper>
              <Input
                type="file"
                name="taxClearanceCertificate"
                onChange={handleTaxClearanceCertificateChange}
                required={docsData.taxClearanceCertificateBase64 === null}
                value={data.taxClearanceCertificate}
                width="100%"
              />
              {taxClearanceCertificateError && (
                <ValidationMessage>
                  {taxClearanceCertificateError}
                </ValidationMessage>
              )}
            </InputWrapper>

            <Label>
              Proof of Address (Copy of Utility Bill or Lease Agreement
              reflecting Business Address) (Required)
            </Label>
            <InputWrapper>
              <Input
                type="file"
                name="utilityBill"
                onChange={handleUtilityBillChange}
                required={docsData.utilityBillBase64 === null}
                value={data.utilityBill}
                width="100%"
              />
              {utilityBillError && (
                <ValidationMessage>{utilityBillError}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Copy of latest Management Accounts</Label>
            <InputWrapper>
              <Input
                type="file"
                name="managementAccounts"
                onChange={handleManagementAccountsChange}
                required={false}
                value={data.managementAccounts}
                width="100%"
              />
              {managementAccountsError && (
                <ValidationMessage>{managementAccountsError}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Copy of latest Audited Financial Statements</Label>
            <InputWrapper>
              <Input
                type="file"
                name="auditedFinanialStatements"
                onChange={handleAuditedFinanialStatementsChange}
                required={false}
                value={data.auditedFinanialStatements}
                width="100%"
              />
              {auditedFinanialStatementsError && (
                <ValidationMessage>
                  {auditedFinanialStatementsError}
                </ValidationMessage>
              )}
            </InputWrapper>
          </ItemWrapper>

          <ItemWrapper>
            <Label>Last 3 Months Bank Statements of Business Account </Label>
            <Label>Month 1 (Required)</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement1"
                onChange={handleBankStatement1Change}
                required={docsData.bankStatement1Base64 === null}
                value={data.bankStatement1}
                width="100%"
              />
              {bankStatement1Error && (
                <ValidationMessage>{bankStatement1Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 2 (Required)</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement2"
                onChange={handleBankStatement2Change}
                required={docsData.bankStatement2Base64 === null}
                value={data.bankStatement2}
                width="100%"
              />
              {bankStatement2Error && (
                <ValidationMessage>{bankStatement2Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 3 (Required)</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement3"
                onChange={handleBankStatement3Change}
                required={docsData.bankStatement3Base64 === null}
                value={data.bankStatement3}
                width="100%"
              />
              {bankStatement3Error && (
                <ValidationMessage>{bankStatement3Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 4</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement4"
                onChange={handleBankStatement4Change}
                required={false}
                value={data.bankStatement4}
                width="100%"
              />
              {bankStatement4Error && (
                <ValidationMessage>{bankStatement4Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 5</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement5"
                onChange={handleBankStatement5Change}
                required={false}
                value={data.bankStatement5}
                width="100%"
              />
              {bankStatement5Error && (
                <ValidationMessage>{bankStatement5Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>Month 6</Label>
            <InputWrapper>
              <Input
                type="file"
                name="bankStatement6"
                onChange={handleBankStatement6Change}
                required={false}
                value={data.bankStatement6}
                width="100%"
              />
              {bankStatement6Error && (
                <ValidationMessage>{bankStatement6Error}</ValidationMessage>
              )}
            </InputWrapper>

            <Label>
              Proof of Business Bank Account not older than 3 Months (not
              applicable where bank statements were provided)
            </Label>
            <InputWrapper>
              <Input
                type="file"
                name="proofOfBankAccount"
                onChange={handleProofOfBankAccountChange}
                required={false}
                value={data.proofOfBankAccount}
                width="100%"
              />
              {proofOfBankAccountError && (
                <ValidationMessage>{proofOfBankAccountError}</ValidationMessage>
              )}
            </InputWrapper>
          </ItemWrapper>
        </FormBody>
      )}
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
