import React from "react";

// Create a context with default values
const MyContext = React.createContext({
  //Authentication
  dataFetched: false,
  getIp: false,
  headers: "",
  idVerification: false,
  idVerificationRequired: false,
  idVerificationFailed: false,
  requestAuthentication: false,

  //Marketing Consent Errors
  channelMarketingConsentError: "",
  externalMarketingConsentError: "",
  telesalesMarketingConsentError: "",
  emailMarketingConsentError: "",
  smsMarketingConsentError: "",
  whatsAppMarketingConsentError: "",
  dataSharingConsentError: "",
  marketingResearchConsentError: "",

  //Dev or Production
  dev: false,

  //Data
  data: {},

  //Directors
  directors: [],

  //Docs
  docsData: {},
  docsSubmitted: false,
  docsSent: false,
  docsError: false,
  docsResponse: "",

  //Errors
  acceptTermsError: "",
  accountNumberError: "",
  ageError: "",
  altNumberError: "",
  auditorTelNumberError: "",
  consentBureauInformationError: "",
  consentFraudIdentityCheckError: "",
  contactNumberError: "",
  creditCostMultipleError: "",
  creditLimitError: "",
  directorsError: "",
  emailError: "",
  employerPostalCodeError: "",
  formError: "",
  faxNumberError: "",
  firstNameError: "",
  idNumberError: "",
  lastNameError: "",
  mobileError: "",
  periodRequiredError: "",
  postalCodeError: "",
  postalPostalCodeError: "",
  signatureError: "",
  shareholdingError: "",
  suretyError: "",
  totalPaidMonthsError: "",
  underAdministrativeOrderError: "",
  underDebtReviewError: "",
  underSequestrationError: "",
  witnessContactNumberError: "",
  workNumberError: "",

  //Laminin
  lamininSubmitted: false,
  lamininSent: false,
  lamininError: false,
  lamininResponse: "",

  //Forms
  applicantDetailsDataCaptured: false,
  applicantContactDetailsDataCaptured: false,
  applicantAddressDataCaptured: false,
  companyOwnershipDataCaptured: false,
  companyFinancialsDataCaptured: false,
  declarationDataCaptured: false,
  docsDataCaptured: false,
  rentalPeriodDataCaptured: false,
});

export default MyContext;
