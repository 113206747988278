export const BankDataFull = [
  {
    Id: 1,
    Name: "Absa Bank Limited",
    BranchCode: "632005",
  },
  {
    Id: 2,
    Name: "Access Bank",
    BranchCode: "410506",
  },
  {
    Id: 3,
    Name: "African Bank",
    BranchCode: "430000",
  },
  {
    Id: 4,
    Name: "Bank Zero",
    BranchCode: "888000",
  },
  {
    Id: 5,
    Name: "Bidvest",
    BranchCode: "462005",
  },
  {
    Id: 6,
    Name: "Capitec Bank",
    BranchCode: "470010",
  },
  {
    Id: 7,
    Name: "Discovery Bank",
    BranchCode: "679000",
  },
  {
    Id: 8,
    Name: "Finbond Mutual Bank",
    BranchCode: "589000",
  },
  {
    Id: 9,
    Name: "First National Bank",
    BranchCode: "250655",
  },
  {
    Id: 10,
    Name: "Grinrod Bank",
    BranchCode: "584000",
  },
  {
    Id: 11,
    Name: "Investec Bank",
    BranchCode: "580105",
  },
  {
    Id: 12,
    Name: "Ithala Bank",
    BranchCode: "754126",
  },
  {
    Id: 13,
    Name: "Mercantile Bank",
    BranchCode: "450105",
  },
  {
    Id: 14,
    Name: "Nedbank Limited",
    BranchCode: "198765",
  },
  {
    Id: 15,
    Name: "Sasfin Bank",
    BranchCode: "683000",
  },
  {
    Id: 16,
    Name: "South African Postbank",
    BranchCode: "460005",
  },
  {
    Id: 17,
    Name: "Standard Bank",
    BranchCode: "051001",
  },
  {
    Id: 18,
    Name: "Tyme Bank",
    BranchCode: "678910",
  },
  {
    Id: 19,
    Name: "U Bank Limited",
    BranchCode: "431010",
  },
];
