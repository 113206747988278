//NPM Packages
import { React } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Theme
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/Theme";

//Context
import MyProvider from "../context/MyProvider";

//Components
import Footer from "./Shared/Footer";
import FinanceApplicationForm from "../routes/FinanceApplicationForm";

const history = createBrowserHistory();

function App() {
  return (
    <div className="main-container">
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <MyProvider>
            <Routes>
              <Route path="/" element={<FinanceApplicationForm />} />
            </Routes>
            <Footer />
          </MyProvider>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
