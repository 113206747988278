import styled from "styled-components";

export const Dropdown = styled.select`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #000;
  border-radius: 3px;
  background-color: #bbb;
  color: ${(props) => props.theme.bodyText};
  font-size: medium;
  font-family: ${({ theme }) => theme.font.body};
  width: ${({ width }) => width || "100%"};

  @media ${({ theme }) => theme.device.laptop} {
    width: ${({ modwidth }) => modwidth || "100%"};
  }

  ::placeholder {
    color: #3e3e3f;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #bbb inset !important;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: #0d0c1d !important;
  }
`;
