import styled from "styled-components";

function getWidthString(span) {
  if (!span) return;

  let width = (span / 12) * 100;
  return `width: ${width}%;`;
}

export const Col = styled.div`
  float: left;

  ${({ $xs }) => ($xs ? getWidthString($xs) : "width: 100%")};

  @media only screen and (min-width: 768px) {
    ${({ $sm }) => $sm && getWidthString($sm)};
  }

  @media only screen and (min-width: 1024px) {
    ${({ $md }) => $md && getWidthString($md)};
  }

  @media only screen and (min-width: 1440px) {
    ${({ $lg }) => $lg && getWidthString($lg)};
  }
`;
