//NPM Packages
import { React, useEffect, useContext } from "react";
import axios from "axios";
import styled from "styled-components";

//Context
import MyContext from "../context/MyContext";

//Components
import ApplicantDetails from "../components/FinanceApplication/ApplicantDetails";
import ApplicantContact from "../components/FinanceApplication/ApplicantContact";
import ApplicantAddress from "../components/FinanceApplication/ApplicantAddress";
import CompanyOwnership from "../components/FinanceApplication/CompanyOwnership";
import CompanyFinancials from "../components/FinanceApplication/CompanyFinancials";
import Declaration from "../components/FinanceApplication/Declaration";
import Documents from "../components/FinanceApplication/Documents";
import RentalPeriod from "../components/FinanceApplication/RentalPeriod";

import Authentication from "../components/Shared/Authentication";
import Header from "../components/Shared/Header";
import { Wrapper } from "../components/styles/Wrapper.styled";

const FormWrapper = styled.div`
  padding: ${(props) => props.theme.space[1]};

  @media ${({ theme }) => theme.device.mobileL} {
    padding: ${(props) => props.theme.space[2]};
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding: ${(props) => props.theme.space[3]};
  }
`;

export default function FinanceApplicationForm(props) {
  const {
    dev,
    data,
    setData,
    docsData,
    setDocsData,

    //Authentication
    headers,
    setHeaders,
    idVerification,
    setIdVerificationRequired,
    idVerificationRequired,
    requestAuthentication,
    setRequestAuthentication,

    //Forms
    applicantDetailsDataCaptured,
    applicantContactDetailsDataCaptured,
    applicantAddressDataCaptured,
    companyOwnershipDataCaptured,
    companyFinancialsDataCaptured,
    docsDataCaptured,
    rentalPeriodDataCaptured,
  } = useContext(MyContext);

  //Authenticate
  useEffect(() => {
    if (!headers && !requestAuthentication) {
      setRequestAuthentication(true);
      axios
        .get(
          dev ? "http://localhost:5000/api/authenticate" : "api/authenticate"
        )
        .then(
          (response) => {
            if (response.status === 200) {
              setHeaders({
                Authorization: "Bearer " + response.data.token,
                "Content-Type": "application/json",
              });
              console.log("Laminin Response: " + response.status);
            } else {
              console.log("Laminin Response: " + response.status);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
          }
        );
    }
  }, [
    dev,
    headers,
    setHeaders,
    requestAuthentication,
    setRequestAuthentication,
  ]);

  //Set Lead Id
  useEffect(() => {
    if (
      new URLSearchParams(new URL(window.location.href).search).get("l") !==
        null &&
      !data.leadId
    ) {
      setIdVerificationRequired(true);
      setData((prevValue) => {
        return {
          ...prevValue,
          leadId: new URLSearchParams(new URL(window.location.href).search).get(
            "l"
          ),
        };
      });

      setDocsData((prevValue) => {
        return {
          ...prevValue,
          leadId: new URLSearchParams(new URL(window.location.href).search).get(
            "l"
          ),
        };
      });
    }
  }, [data, setData, docsData, setDocsData, setIdVerificationRequired]);

  return (
    <Wrapper>
      <Header
        name="iStore Business"
        description="iStore Business Finance Application Form"
        logoPath="../images/istore-logo.png"
      />
      {!idVerification && idVerificationRequired && (
        <Authentication
          phone="011 535 9000"
          email="financeapps@istorebusiness.co.za"
        />
      )}

      {(idVerification || !idVerificationRequired) && (
        <FormWrapper>
          {!applicantDetailsDataCaptured && (
            <ApplicantDetails
              name="iStore Business"
              formName="Finance Application Form"
            />
          )}

          {!applicantContactDetailsDataCaptured &&
            applicantDetailsDataCaptured && (
              <ApplicantContact
                name="iStore Business"
                formName="Finance Application Form"
              />
            )}

          {!applicantAddressDataCaptured &&
            applicantContactDetailsDataCaptured &&
            applicantDetailsDataCaptured && (
              <ApplicantAddress
                name="iStore Business"
                formName="Finance Application Form"
              />
            )}

          {!companyOwnershipDataCaptured &&
            applicantAddressDataCaptured &&
            applicantContactDetailsDataCaptured &&
            applicantDetailsDataCaptured && (
              <CompanyOwnership
                name="iStore Business"
                formName="Finance Application Form"
              />
            )}

          {!companyFinancialsDataCaptured &&
            companyOwnershipDataCaptured &&
            applicantAddressDataCaptured &&
            applicantContactDetailsDataCaptured &&
            applicantDetailsDataCaptured && (
              <CompanyFinancials
                name="iStore Business"
                formName="Finance Application Form"
              />
            )}

          {!rentalPeriodDataCaptured &&
            companyFinancialsDataCaptured &&
            companyOwnershipDataCaptured &&
            applicantAddressDataCaptured &&
            applicantContactDetailsDataCaptured &&
            applicantDetailsDataCaptured && (
              <RentalPeriod
                name="iStore Business"
                formName="Finance Application Form"
              />
            )}

          {!docsDataCaptured &&
            rentalPeriodDataCaptured &&
            companyFinancialsDataCaptured &&
            companyOwnershipDataCaptured &&
            applicantAddressDataCaptured &&
            applicantContactDetailsDataCaptured &&
            applicantDetailsDataCaptured && (
              <Documents
                name="iStore Business"
                formName="Finance Application Form"
              />
            )}

          {docsDataCaptured &&
            rentalPeriodDataCaptured &&
            companyFinancialsDataCaptured &&
            companyOwnershipDataCaptured &&
            applicantAddressDataCaptured &&
            applicantContactDetailsDataCaptured &&
            applicantDetailsDataCaptured && (
              <Declaration
                name="iStore Business"
                formName="Finance Application Form"
                logoPath="../images/istore-black.png"
                phone="011 535 9000"
                email="financeapps@istorebusiness.co.za"
              />
            )}
        </FormWrapper>
      )}
    </Wrapper>
  );
}
