import { React, useContext, useEffect } from "react";
import axios from "axios";

//Context
import MyContext from "../../context/MyContext";

function Footer() {
  const { dev, data, setData, headers, getIp, setGetIp } =
    useContext(MyContext);
  const year = new Date().getFullYear();

  //Get IP Address
  useEffect(() => {
    if (headers && !data.ip && !getIp) {
      setGetIp(true);
      axios
        .post(dev ? "http://localhost:5000/api/get-ip" : "api/get-ip", data, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response: " + response.status);
              setData((prevValue) => {
                return {
                  ...prevValue,
                  ip: response.data,
                };
              });
            } else {
              console.log("Laminin Response: " + response.status);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
          }
        );
    }
  }, [dev, data, getIp, setGetIp, headers, setData]);

  //Get Geo Location
  useEffect(() => {
    if ("geolocation" in navigator && data.position.latitude === null) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setData((prevValue) => {
          return {
            ...prevValue,
            position: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          };
        });
      });
    }
  }, [data, setData]);

  return (
    <footer>
      <p>IP Address: {data.ip}</p>
      {data.position.latitude && data.position.longitude ? (
        <p>
          Latitude: {data.position.latitude}, Longitude:{" "}
          {data.position.longitude}
        </p>
      ) : (
        <p>Geolocation is not available in your browser</p>
      )}
      <p>iStorebusiness Copyright ⓒ {year}</p>
    </footer>
  );
}

export default Footer;
