import React, { useState } from "react";
import MyContext from "./MyContext";

function MyProvider({ children }) {
  //Authentication
  const [dataFetched, setDataFetched] = useState(false);
  const [getIp, setGetIp] = useState(false);
  const [headers, setHeaders] = useState(null);
  const [idVerification, setIdVerification] = useState(false);
  const [idVerificationRequired, setIdVerificationRequired] = useState(false);
  const [idVerificationFailed, setIdVerificationFailed] = useState(false);
  const [requestAuthentication, setRequestAuthentication] = useState(false);

  //Dev or Production
  const [dev, setDev] = useState(false);

  //Data
  const [data, setData] = useState({
    leadId: "",

    //Authentication
    otp: "",
    acceptTermsAndConditions: "",

    //Applicant Data
    companyName: "",
    companyRegistrationNumber: "",
    tradingName: "",
    sizeOfOrganisation: "",
    taxNumber: "",
    holdingCompany: "",
    holdingCompanyRegNumber: "",
    industry: "",
    vatNumber: "",
    companyType: "",
    applicationCompletedByName: "",
    applicationCompletedByLastName: "",
    applicationCompletedByDesignation: "",
    signedDate: "",
    division: "",
    dealType: "",

    //Applicant Contact Data
    contactFirstName: "",
    contactLastName: "",
    telNumber: "",
    faxNumber: "",
    altNumber: "",
    email: "",
    mobile: "",

    //Applicant Address
    complex: "",
    streetAddress: "",
    suburb: "",
    city: "",
    province: "",
    postalCode: "",
    country: "",
    physicalPostalSame: "No",
    postalComplex: "",
    postalAddress: "",
    postalSuburb: "",
    postalCity: "",
    postalProvince: "",
    postalPostalCode: "",
    numberOfYearsAtResidence: "",
    premisesOwnership: "",
    consultant: "",

    //Director Data
    firstName: "",
    lastName: "",
    idNumber: "",
    shareholding: "",
    willSignSurety: "",
    firstName1: "",
    lastName1: "",
    idNumber1: "",
    shareholding1: "",
    willSignSurety1: "",
    firstName2: "",
    lastName2: "",
    idNumber2: "",
    shareholding2: "",
    willSignSurety2: "",
    firstName3: "",
    lastName3: "",
    idNumber3: "",
    shareholding3: "",
    willSignSurety3: "",

    //Financial Data
    accountNumber: "",
    bankName: "",
    branchCode: "",
    accountType: "",
    dateOpened: "",
    overdraftLimit: "",
    netAssetValue: "",
    annualTurnover: "",
    nameOfAuditor: "",
    auditorContactPerson: "",
    auditorTelNumber: "",

    //Location
    ip: "",
    position: { latitude: null, longitude: null },
    signedLocation: "",

    //Rental
    periodRequired: "",

    //Docs
    companyRegistration: "",
    idCopies1: "",
    idCopies2: "",
    idCopies3: "",
    taxClearanceCertificate: "",
    bankStatement1: "",
    bankStatement2: "",
    bankStatement3: "",
    bankStatement4: "",
    bankStatement5: "",
    bankStatement6: "",
    proofOfBankAccount: "",
    utilityBill: "",
    managementAccounts: "",
    auditedFinanialStatements: "",
  });

  //Directors
  const [directors, setDirectors] = useState([]);

  //Docs
  const [docsData, setDocsData] = useState({
    leadId: "",
    pdfName: "",
    pdfBase64: null,
    companyRegistrationName: "",
    companyRegistrationBase64: null,
    idCopies1Name: "",
    idCopies1Base64: null,
    idCopies2Name: "",
    idCopies2Base64: null,
    idCopies3Name: "",
    idCopies3Base64: null,
    taxClearanceCertificateName: "",
    taxClearanceCertificateBase64: null,
    bankStatement1Name: "",
    bankStatement1Base64: null,
    bankStatement2Name: "",
    bankStatement2Base64: null,
    bankStatement3Name: "",
    bankStatement3Base64: null,
    bankStatement4Name: "",
    bankStatement4Base64: null,
    bankStatement5Name: "",
    bankStatement5Base64: null,
    bankStatement6Name: "",
    bankStatement6Base64: null,
    proofOfBankAccountName: "",
    proofOfBankAccountBase64: null,
    utilityBillName: "",
    utilityBillBase64: null,
    managementAccountsName: "",
    managementAccountsBase64: null,
    auditedFinanialStatementsName: "",
    auditedFinanialStatementsBase64: null,
  });

  const [docsSubmitted, setDocsSubmitted] = useState(false);
  const [docsSent, setDocsSent] = useState(false);
  const [docsError, setDocsError] = useState(false);
  const [docsResponse, setDocsResponse] = useState(false);

  //Errors
  const [acceptTermsError, setAcceptTermsError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [auditorTelNumberError, setAuditorTelNumberError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [altNumberError, setAltNumberError] = useState("");
  const [consentBureauInformationError, setConsentBureauInformationError] =
    useState("");
  const [consentFraudIdentityCheckError, setConsentFraudIdentityCheckError] =
    useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [creditCostMultipleError, setCreditCostMultipleError] = useState("");
  const [creditLimitError, setCreditLimitError] = useState("");
  const [directorsError, setDirectorsError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [employerPostalCodeError, setEmployerPostalCodeError] = useState("");
  const [formError, setFormError] = useState("");
  const [faxNumberError, setFaxNumberError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [idNumberError, setIdNumberError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [periodRequiredError, setPeriodRequiredError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [postalPostalCodeError, setPostalPostalCodeError] = useState("");
  const [signatureError, setSignatureError] = useState("");
  const [shareholdingError, setShareholdingError] = useState("");
  const [suretyError, setSuretyError] = useState("");
  const [totalPaidMonthsError, setTotalPaidMonthsError] = useState("");
  const [underAdministrativeOrderError, setUnderAdministrativeOrderError] =
    useState("");
  const [underDebtReviewError, setUnderDebtReviewError] = useState("");
  const [underSequestrationError, setUnderSequestrationError] = useState("");
  const [witnessContactNumberError, setWitnessContactNumberError] =
    useState("");
  const [workNumberError, setWorkNumberError] = useState("");

  //Errors Marketing Consent
  const [channelMarketingConsentError, setChannelMarketingConsentError] =
    useState("");
  const [externalMarketingConsentError, setExternalMarketingConsentError] =
    useState("");
  const [telesalesMarketingConsentError, setTelesalesMarketingConsentError] =
    useState("");
  const [emailMarketingConsentError, setEmailMarketingConsentError] =
    useState("");
  const [smsMarketingConsentError, setSmsMarketingConsentError] = useState("");
  const [whatsAppMarketingConsentError, setWhatsAppMarketingConsentError] =
    useState("");
  const [dataSharingConsentError, setDataSharingConsentError] = useState("");
  const [marketingResearchConsentError, setMarketingResearchConsentError] =
    useState("");

  //Forms
  const [applicantDetailsDataCaptured, setApplicantDetailsDataCaptured] =
    useState(false);
  const [
    applicantContactDetailsDataCaptured,
    setApplicantContactDetailsDataCaptured,
  ] = useState(false);
  const [applicantAddressDataCaptured, setApplicantAddressDataCaptured] =
    useState(false);
  const [companyOwnershipDataCaptured, setCompanyOwnershipDataCaptured] =
    useState(false);
  const [companyFinancialsDataCaptured, setCompanyFinancialsDataCaptured] =
    useState(false);
  const [declarationDataCaptured, setDeclarationDataCaptured] = useState(false);
  const [docsDataCaptured, setDocsDataCaptured] = useState(false);
  const [rentalPeriodDataCaptured, setRentalPeriodDataCaptured] =
    useState(false);

  //Laminin
  const [lamininSubmitted, setLamininSubmitted] = useState(false);
  const [lamininSent, setLamininSent] = useState(false);
  const [lamininError, setLamininError] = useState(false);
  const [lamininResponse, setLamininResponse] = useState({});

  return (
    <MyContext.Provider
      value={{
        //Authentication
        dataFetched,
        setDataFetched,
        getIp,
        setGetIp,
        headers,
        setHeaders,
        idVerification,
        setIdVerification,
        idVerificationRequired,
        setIdVerificationRequired,
        idVerificationFailed,
        setIdVerificationFailed,
        requestAuthentication,
        setRequestAuthentication,

        //Dev or Production
        dev,
        setDev,

        //Data
        data,
        setData,

        //Directors
        directors,
        setDirectors,

        //Docs
        docsData,
        setDocsData,
        docsSubmitted,
        setDocsSubmitted,
        docsSent,
        setDocsSent,
        docsError,
        setDocsError,
        docsResponse,
        setDocsResponse,

        //Errors
        acceptTermsError,
        setAcceptTermsError,
        accountNumberError,
        setAccountNumberError,
        ageError,
        setAgeError,
        altNumberError,
        setAltNumberError,
        auditorTelNumberError,
        setAuditorTelNumberError,
        consentBureauInformationError,
        setConsentBureauInformationError,
        consentFraudIdentityCheckError,
        setConsentFraudIdentityCheckError,
        contactNumberError,
        setContactNumberError,
        creditCostMultipleError,
        setCreditCostMultipleError,
        creditLimitError,
        setCreditLimitError,
        directorsError,
        setDirectorsError,
        emailError,
        setEmailError,
        employerPostalCodeError,
        setEmployerPostalCodeError,
        formError,
        setFormError,
        faxNumberError,
        setFaxNumberError,
        firstNameError,
        setFirstNameError,
        idNumberError,
        setIdNumberError,
        lastNameError,
        setLastNameError,
        mobileError,
        setMobileError,
        postalCodeError,
        setPostalCodeError,
        postalPostalCodeError,
        periodRequiredError,
        setPeriodRequiredError,
        shareholdingError,
        setShareholdingError,
        setPostalPostalCodeError,
        signatureError,
        setSignatureError,
        suretyError,
        setSuretyError,
        totalPaidMonthsError,
        setTotalPaidMonthsError,
        underAdministrativeOrderError,
        setUnderAdministrativeOrderError,
        underDebtReviewError,
        setUnderDebtReviewError,
        underSequestrationError,
        setUnderSequestrationError,
        witnessContactNumberError,
        setWitnessContactNumberError,
        workNumberError,
        setWorkNumberError,

        //Errors Marketing Consent
        channelMarketingConsentError,
        setChannelMarketingConsentError,
        externalMarketingConsentError,
        setExternalMarketingConsentError,
        telesalesMarketingConsentError,
        setTelesalesMarketingConsentError,
        emailMarketingConsentError,
        setEmailMarketingConsentError,
        smsMarketingConsentError,
        setSmsMarketingConsentError,
        whatsAppMarketingConsentError,
        setWhatsAppMarketingConsentError,
        dataSharingConsentError,
        setDataSharingConsentError,
        marketingResearchConsentError,
        setMarketingResearchConsentError,

        //Forms
        applicantDetailsDataCaptured,
        setApplicantDetailsDataCaptured,
        applicantContactDetailsDataCaptured,
        setApplicantContactDetailsDataCaptured,
        applicantAddressDataCaptured,
        setApplicantAddressDataCaptured,
        companyOwnershipDataCaptured,
        setCompanyOwnershipDataCaptured,
        companyFinancialsDataCaptured,
        setCompanyFinancialsDataCaptured,
        declarationDataCaptured,
        setDeclarationDataCaptured,
        docsDataCaptured,
        setDocsDataCaptured,
        rentalPeriodDataCaptured,
        setRentalPeriodDataCaptured,

        //Laminin
        lamininSubmitted,
        setLamininSubmitted,
        lamininSent,
        setLamininSent,
        lamininError,
        setLamininError,
        lamininResponse,
        setLamininResponse,
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

export default MyProvider;
