import styled from "styled-components";
import { Label, Input } from "./RadioButton.styled";

const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  &:hover {
    cursor: pointer;
  }
`;

const RadioButton = (props) => {
  return (
    <Wrapper>
      <Input
        type="radio"
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        checked={props.checked}
      />
      <Label htmlFor={props.id}>{props.label}</Label>
    </Wrapper>
  );
};

export default RadioButton;
